import React, { useState, useEffect } from "react";
import Layout from "../layout";
import styled from "styled-components";
import ReactPlayer from "react-player";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import {
  navyBlue,
  mainRed,
  mainWhite,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { moveBgColor } from "../components/common/animations";
import VideoModal from "../components/video-modal";
import Arrow from "../images/svg/right-arrow.svg";
import { Link as Spy } from "react-scroll";
import { getVimeoId } from "../components/common/functions";

const Wrapper = styled.div`
  padding-bottom: 120px;
  padding-top: 124px;
  @media ${screen.small} {
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto 20px auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      font-size: 2.7rem;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto 45px auto;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .banner {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;

    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .intro {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    /* &__logo {
      max-width: 400px;
      margin-top: 30px;
    } */

    &__description {
      margin-top: 30px;
      max-width: 1000px;
      color: ${navyBlue};
      p {
        font-family: ${gotham};
        font-size: 1rem;
        margin-top: 16px;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        &:first-child {
          margin-top: 0;
        }
      }
      strong {
        font-family: ${gothamBold};
      }
      a {
        color: ${navyBlue};
        font-family: ${gothamBold};
        &:hover {
          text-decoration: underline;
        }
      }

      h1 {
        font-size: 2rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 2.2rem;
        }
      }
      h2 {
        font-size: 1.8rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 2rem;
        }
      }
      h3 {
        font-size: 1.6rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 1.8rem;
        }
      }
      h4 {
        font-size: 1.5rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 1.6rem;
        }
      }
      h5 {
        font-size: 1.3rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 1.4rem;
        }
      }
      h6 {
        font-size: 1.1rem;
        font-family: ${gothamBold};
        margin: 8px 0;
        @media ${screen.small} {
          margin: 10px 0;
          font-size: 1.2rem;
        }
      }
    }
  }

  .office-section {
    background: crimson;
    margin-top: 45px;
    background-image: ${(props) =>
      `linear-gradient(90deg, ${props.bgColour[0].colour}, ${props.bgColour[1].colour}, ${props.bgColour[2].colour}, ${props.bgColour[3].colour})`};
    background-size: 360%;
    animation: ${moveBgColor} 8s infinite alternate;
    padding: 45px 25px;
    @media ${screen.small} {
      padding: 90px 45px;
      margin-top: 90px;
    }
    @media ${screen.xLarge} {
      padding: 90px 0;
    }

    .heading {
      color: ${mainWhite};
      font-family: ${aku};
      font-size: 2.1rem;
      padding: 0;
      @media ${screen.small} {
        font-size: 2.7rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }

      &--tablet {
        margin-bottom: 20px;
        @media ${screen.medium} {
          display: none;
        }
      }

      &--desktop {
        display: none;
        @media ${screen.medium} {
          display: block;
        }
      }
    }

    .content {
      max-width: ${innerWidth};
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      @media ${screen.medium} {
        flex-direction: row;
      }

      &__texts-container {
        max-width: 1000px;
        width: 100%;

        .description {
          margin-top: 30px;
          margin-bottom: 30px;
          max-width: 1000px;
          color: ${mainWhite};

          p {
            font-family: ${gotham};
            font-size: 1rem;
            margin-top: 16px;
            @media ${screen.small} {
              font-size: 1.1rem;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          strong {
            font-family: ${gothamBold};
          }
          a {
            color: ${mainWhite};
            font-family: ${gothamBold};
            &:hover {
              text-decoration: underline;
            }
          }

          h1 {
            font-size: 2rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 2.2rem;
              margin: 10px 0;
            }
          }
          h2 {
            font-size: 1.8rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 2rem;
              margin: 10px 0;
            }
          }
          h3 {
            font-size: 1.6rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 1.8rem;
              margin: 10px 0;
            }
          }
          h4 {
            font-size: 1.5rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 1.6rem;
              margin: 10px 0;
            }
          }
          h5 {
            font-size: 1.3rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 1.4rem;
              margin: 10px 0;
            }
          }
          h6 {
            font-size: 1.15rem;
            font-family: ${gothamBold};
            margin: 8px 0;
            @media ${screen.small} {
              font-size: 1.2rem;
              margin: 10px 0;
            }
          }
        }

        .client-title {
          color: ${mainWhite};
          font-family: ${gothamBold};
          font-size: 1rem;
          @media ${screen.small} {
            font-size: 1.3rem;
          }
        }

        .client-list {
          .client {
            color: ${mainWhite};
            font-size: 1rem;
            font-family: ${gotham};
            margin: 8px 0 8px 18px;
            @media ${screen.small} {
              font-size: 1.1rem;
              margin: 10px 0 10px 35px;
            }

            svg {
              width: 7px;
              margin-right: 10px;
              .body {
                fill: ${mainWhite};
              }
            }
          }
        }
      }

      &__img {
        width: 100%;
        padding-left: 0;
        order: -1;
        max-width: 508px;
        @media ${screen.medium} {
          padding-left: 140px;
          max-width: inherit;
          order: 1;
        }
      }
    }
  }

  .featured-project {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-top: 90px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      @media ${screen.small} {
        font-size: 2rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 30px;
      }

      .video-item {
        width: 100%;
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        @media ${screen.small} {
          margin: 20px 0;
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 345px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xLarge} {
              padding-right: 0;
            }

            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 3px;
              margin: 18px 0;
              background: ${mainWhite};
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: "";
        width: 100%;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
        @media ${screen.large} {
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
        }
      }
    }
    &__paginate {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      max-width: 1000px;

      .box {
        display: block;
        cursor: pointer;
        width: 38px;
        height: 40px;
        border: 1px ${navyBlue} solid;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: ${transHover};

        &:hover {
          border: 1px ${mainRed} solid;
        }

        &:hover .body {
          fill: ${mainRed};
          transition: ${transHover};
        }

        &:hover .page-num {
          color: ${mainRed};
          transition: ${transHover};
        }

        .page-num {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1.1rem;
        }

        &--active {
          background: ${mainRed};
          border: 1px ${mainRed} solid;
          pointer-events: none;
          &:hover {
            background: ${mainWhite};
            border: 1px ${navyBlue} solid;
          }
          &:hover .page-num {
            color: ${navyBlue};
          }
          .page-num {
            color: ${mainWhite};
          }
        }
      }
    }

    &__projects-link {
      background: ${navyBlue};
      transition: ${transHover};
      display: table;
      padding: 16px 29px;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      &:hover {
        background: ${mainRed};
      }
    }
  }

  .contact-section {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-top: 90px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      @media ${screen.small} {
        font-size: 2rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__map-details-row {
      display: flex;
      margin-top: 45px;
      flex-direction: column;
      @media ${screen.medium} {
        flex-direction: row;
      }

      .map {
        iframe {
          width: 100%;
          height: 230px;
          border: 0;
          @media ${screen.small} {
            height: 370px;
          }
          @media ${screen.medium} {
            width: 630px;
            height: 330px;
          }
          @media ${screen.large} {
            width: 740px;
            height: 420px;
          }
          @media ${screen.xLarge} {
            width: 1040px;
            height: 500px;
          }
        }
      }

      .details {
        margin-top: 20px;
        @media ${screen.medium} {
          margin-top: 0;
          padding-left: 45px;
          align-self: flex-end;
        }

        h6 {
          color: ${navyBlue};
          font-family: ${gothamBold};
          font-size: 1.2rem;
        }

        p {
          color: ${navyBlue};
          font-family: ${gotham};
          font-size: 1rem;
          white-space: pre-line;
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }
        a {
          color: ${navyBlue};
          transition: ${transHover};
          &:hover {
            color: ${mainRed};
          }
        }
      }
    }
  }
`;

const WollongongPage = ({ data }) => {
  const [isTablet, setTablet] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160
  );
  const [num, setNum] = useState(
    typeof window !== "undefined" && window.innerWidth < 1160 ? 4 : 9
  );
  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );
  const [paginateFrom, setPaginateFrom] = useState(0);
  const [paginateTo, setPaginateTo] = useState(num);
  const [currentPage, setCurrentPage] = useState(1);
  const projectList = data.content.data.projects;
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Wollongong`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Wollongong`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Wollongong`;
  const imgBanner =
    data.content.data.image_banner.gatsbyImageData &&
    data.content.data.image_banner.gatsbyImageData;

  const imgURL = data.content.data.image_banner.url;

  const altBanner = data.content.data.image_banner.alt;

  const mainDescription = data.content.data.description.html;

  const officeTitle = data.content.data.title;

  const officeDescription = data.content.data.description1.html;

  const backgroundColour =
    data.content.data.background_colour.length === 4
      ? data.content.data.background_colour
      : [
          { colour: mainRed },
          { colour: mainRed },
          { colour: mainRed },
          { colour: mainRed },
        ];

  const featuredWorkList = isTablet
    ? data.content.data.projects.slice(0, 2)
    : data.content.data.projects;

  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
    // window.location.hash = videoId;
  };

  useEffect(() => {
    const CheckBrowserWidth = () => {
      if (window.innerWidth > 1160) {
        setTablet(false);
      } else {
        setTablet(true);
      }
    };
    window.addEventListener("resize", CheckBrowserWidth);

    return () => {
      window.removeEventListener("resize", CheckBrowserWidth);
    };
  }, [isTablet]);

  const projectsPage = new Array(Math.ceil(projectList.length / num)).fill(" ");

  const renderProjects = projectList.slice(paginateFrom, paginateTo);

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={imgURL} />
      <Wrapper bgColour={backgroundColour}>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div className="banner">
          {imgBanner && (
            <GatsbyImage image={imgBanner} alt={altBanner || "Banner"} />
          )}
        </div>
        <div className="intro">
          <div
            className="intro__description"
            dangerouslySetInnerHTML={{ __html: mainDescription }}
          />
        </div>
        <section id="second-office" className="office-section">
          <h2 className="heading heading--tablet">
            <Arrow />
            {officeTitle}
          </h2>
          <div className="content">
            <div className="content__texts-container">
              <h2 className="heading heading--desktop">
                <Arrow />
                {officeTitle}
              </h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: officeDescription }}
              />
            </div>
          </div>
        </section>
        <section className="featured-project">
          {featuredWorkList.length > 0 && (
            <h2 className="featured-project__heading">
              <Spy
                to="latest-wollongong"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-150}
              >
                <Arrow />
              </Spy>
              Our Work
            </h2>
          )}
          <ul className="featured-project__video-list">
            {renderProjects.map(
              (item, i) =>
                item.project.document && (
                  <li
                    className="video-item"
                    onClick={() =>
                      viewVideo(
                        getVimeoId(item.project.document.data.video_url)
                      )
                    }
                    key={i}
                  >
                    <div className="video-item__texts">
                      <h2 className="video-title">
                        {item.project.document.data.title.text}
                      </h2>
                      {item.project.document.data.categories.map((cat, i) => (
                        <p className="video-subtitle" key={i}>
                          {cat.category.document &&
                            cat.category.document.data.title.text}
                          <span className="video-subtitle--comma">,</span>
                        </p>
                      ))}
                    </div>
                    <ReactPlayer
                      className="video-item__video"
                      url={item.project.document.data.video_url}
                      light={`https://vumbnail.com/${getVimeoId(
                        item.project.document.data.video_url
                      )}.jpg`}
                    />
                  </li>
                )
            )}
          </ul>
          {projectsPage.length > 1 && (
            <div className="featured-project__paginate">
              {projectsPage.map((empty, i) => (
                <span
                  className={currentPage === i + 1 ? `box box--active` : `box`}
                  key={i + 1}
                  onClick={() => {
                    setPaginateFrom(num * i);
                    setPaginateTo(num * (i + 1));
                    setCurrentPage(i + 1);
                  }}
                >
                  <span className="page-num">{i + 1}</span>
                </span>
              ))}
            </div>
          )}
        </section>

        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
      </Wrapper>
    </Layout>
  );
};

export default WollongongPage;

export const dataQuery = graphql`
  {
    content: prismicWollongongPage {
      data {
        title_tag
        meta_description
        address
        projects {
          project {
            document {
              ... on PrismicProject {
                data {
                  title {
                    text
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  date
                  video_url
                  credits {
                    description
                  }
                }
              }
            }
          }
        }
        image_banner {
          alt
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        page {
          text
        }

        description {
          html
        }
        title
        description1 {
          html
        }
        background_colour {
          colour
        }
      }
    }
  }
`;
