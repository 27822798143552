// modal for videos

import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import ReactPlayer from "react-player";
import ScrollLock from "react-scrolllock";
import {
  mainWhite,
  mainRed,
  navyBlue,
  aku,
  gotham,
  gothamBold,
  transHover,
  screen,
} from "../common/variables";
import { scaleInBig } from "../common/animations";
import { getVimeoId } from "../common/functions";
import Close from "../../images/svg/close-icon.svg";

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  padding: 15px;
  height: 100%;
  @media ${screen.small} {
    padding: 60px;
  }
  @media ${screen.medium} {
    padding: 100px;
  }
  @media ${screen.large} {
    padding: 50px;
  }
  @media ${screen.xLarge} {
    padding: 0;
  }

  .inner {
    max-width: 1280px;
    width: 100%;
    background: ${mainWhite};
    padding: 8px 8px 16px 8px;
    position: relative;
    animation: ${scaleInBig} 0.4s ease-in-out;
    @media ${screen.small} {
      padding: 12px 12px 26px 12px;
      max-width: 750px;
    }
    @media ${screen.medium} {
      margin-top: 25px;
      max-width: 800px;
    }
    @media ${screen.large} {
      margin-top: 100px;
      max-width: 940px;
    }
    @media ${screen.miniLarge} {
      margin-top: 110px;
      max-width: 730px;
    }
    @media ${screen.xLarge} {
      margin-top: 0;
      max-width: 1280px;
    }

    &__video-container {
      position: relative;
      padding-top: 56.25%;
      width: 100%;
      background: #000;
      margin: 0 auto;

      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }

    &__texts-container {
      margin-top: 10px;
      padding: 0 6px;
      @media ${screen.small} {
        margin-top: 28px;
      }
      .headings {
        display: flex;
        justify-content: space-between;

        &__title {
          color: ${navyBlue};
          font-family: ${aku};
          font-size: 1.7rem;
          padding-right: 30px;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }

        &__year {
          color: ${mainRed};
          font-family: ${aku};
          font-size: 1.7rem;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
        }
      }

      .descriptions {
        display: none;
        margin-top: 4px;
        @media ${screen.large} {
          display: flex;
        }

        h6 {
          color: ${mainRed};
          font-family: ${gothamBold};
          font-size: 0.95rem;
          margin-right: 7px;
          @media ${screen.small} {
            font-size: 1.15rem;
            margin-right: 10px;
          }
        }

        li {
          max-width: 600px;
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          p {
            color: ${navyBlue};
            font-family: ${gotham};
            font-size: 0.92rem;
            white-space: pre-line;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }
      }
    }

    .close-icon {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: -6px;
      background: ${mainWhite};
      border-radius: 50%;
      width: 47px;
      height: 47px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${screen.small} {
        width: 56px;
        height: 56px;
        top: -23px;
        right: -23px;
      }

      @media ${screen.large} {
        width: 60px;
        height: 60px;
        top: -27px;
        right: -27px;
      }

      svg {
        width: 17px;
        @media ${screen.small} {
          width: 20px;
        }
        .stroke {
          fill: ${navyBlue};
          transition: ${transHover};
        }
        &:hover {
          .stroke {
            fill: ${mainRed};
          }
        }
      }
    }
  }
`;

const VideoModal = () => {
  // all video projects from Prismic
  const { projects } = useStaticQuery(dataQuery);

  // url hash state
  const [hash, setHash] = useState(
    typeof window !== "undefined" && window.location.hash
  );

  // hide/show modal state
  const [showModal, setModal] = useState(false);

  // vimeo url state
  const [videoUrl, setVideoUrl] = useState("");

  // video title state
  const [videoTitle, setVideoTitle] = useState("");

  // video date state
  const [videoDate, setVideoDate] = useState("");

  // video credits state
  const [videoCredits, setVideoCredits] = useState("");

  // transform projects array into object
  const allProjects = projects.edges.reduce((cur, next, i) => {
    let x = getVimeoId(next.node.data.video_url);
    cur[x] = next;
    return cur;
  }, {});

  useEffect(() => {
    // only open the video modal if video id exist in the allProjects object.
    // video id is based on the hash url
    // if it does exist, start filling up those state details e.g "videoTitle"
    if (allProjects[hash.substring(1)]) {
      setVideoUrl(allProjects[hash.substring(1)].node.data.video_url);
      setVideoTitle(allProjects[hash.substring(1)].node.data.title);
      setVideoDate(
        allProjects[hash.substring(1)].node.data.date
          ? // if published date is empty on Prismic just get the first publication date of the video content
            // and slice it to only get the year
            allProjects[hash.substring(1)].node.data.date.slice(0, 4)
          : allProjects[hash.substring(1)].node.first_publication_date.slice(
              0,
              4
            )
      );
      setVideoCredits(allProjects[hash.substring(1)].node.data.credits);
      setModal(true);
    } else {
      setVideoUrl("");
      setVideoTitle("");
      setVideoDate("");
      setVideoCredits("");
      setModal(false);
    }
  }, [hash]);

  useEffect(() => {
    setHash(window.location.hash);
  });

  // listener for closing the modal by user click and key press
  useEffect(() => {
    if (showModal) {
      const detectTarget = e => {
        if (e.target.nodeName === "svg" || e.target.nodeName === "path") {
          return false;
        } else {
          // close modal if user has clicked outside the modal
          if (e.target.className.includes("Wrapper")) {
            closeModal();
          }
        }
      };
      // close modal if user press esc key
      const detectKey = e => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };

      window.addEventListener("click", detectTarget);

      window.addEventListener("keydown", detectKey);

      return () => {
        window.removeEventListener("click", detectTarget);
        window.removeEventListener("keydown", detectKey);
      };
    }
  });

  // close video modal
  const closeModal = e => {
    window.history.replaceState(null, null, "#");
    setModal(false);
  };

  return (
    <React.Fragment>
      {showModal ? (
        <ScrollLock>
          <Wrapper>
            <div className="inner">
              <div className="inner__video-container">
                <ReactPlayer
                  className="video"
                  url={videoUrl}
                  controls={true}
                  preload="true"
                />
              </div>
              <div className="inner__texts-container">
                <div className="headings">
                  <h1 className="headings__title">{videoTitle.text}</h1>
                </div>
                <div className="descriptions">
                  {videoCredits[0].description && <h6>Credits:</h6>}
                  <ul>
                    {videoCredits.map((credit, i) => (
                      <li key={i}>
                        <p>{credit.description}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <span className="close-icon" onClick={closeModal}>
                <Close />
              </span>
            </div>
          </Wrapper>
        </ScrollLock>
      ) : null}
    </React.Fragment>
  );
};

export default VideoModal;

const dataQuery = graphql`
  {
    projects: allPrismicProject {
      edges {
        node {
          first_publication_date
          data {
            title {
              text
            }
            credits {
              description
            }
            date
            video_url
          }
        }
      }
    }
    background: prismicHomePage {
      data {
        background_video {
          document {
            ... on PrismicProject {
              data {
                video_url
              }
            }
          }
        }
      }
    }
  }
`;
